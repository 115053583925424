import React, { useState, useEffect, useMemo, useCallback, memo } from "react";

// Memoized MasonryLayout component
const MasonryLayout = memo(({ children, columnCount, gapSize }) => {
  const distributeChildren = useCallback((children, columnCount) => {
    const columns = Array.from({ length: columnCount }, () => []);
    let columnIndex = 0;

    React.Children.forEach(children, (child) => {
      columns[columnIndex].push(child);
      columnIndex = (columnIndex + 1) % columnCount;
    });

    return columns;
  }, []);

  const columns = useMemo(() =>
    distributeChildren(children, columnCount),
    [children, columnCount, distributeChildren]
  );

  return (
    <div className={`flex gap-${gapSize}`}>
      {columns.map((column, index) => (
        <div key={index} className={`flex flex-col gap-${gapSize} flex-1 mb-32`}>
          {column}
        </div>
      ))}
    </div>
  );
});

// Memoized ImageItem component
const ImageItem = memo(({ image, isSelected, columnCount, onToggle }) => {
  return (
    <div
      className="w-full relative cursor-pointer transition-opacity duration-300"
      onClick={() => onToggle(image.id)}
    >
      <img
        src={image.src}
        alt={`Gallery ${image.id}`}
        className={`w-full rounded-lg shadow-md transition-all duration-300 ${isSelected ? "brightness-75" : "brightness-100"
          }`}
        style={{ height: `${image.height}px`, objectFit: "cover" }}
      />
      {isSelected && (
        <div className="absolute top-2 right-2 bg-white rounded-md shadow-sm p-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`h-${8 - columnCount} w-${8 - columnCount} text-primary`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M5 13l4 4L19 7"
            />
          </svg>
        </div>
      )}
    </div>
  );
});

// Main MasonryGallery component wrapped in memo

const MasonryGallery = memo(({
  onSelectionChange,
  images,
  gapSize = 2,
  defaultSelectedImages = [],
  hideSelectedImages = false,
}) => {
  const initializeSelection = (ids) => ids.reduce((acc, id) => ({ ...acc, [id]: true }), {});

  const [selectedImages, setSelectedImages] = useState(() => initializeSelection(defaultSelectedImages));
  const [hiddenImages, setHiddenImages] = useState(() => hideSelectedImages ? initializeSelection(defaultSelectedImages) : {});

  const sortedImages = useMemo(() => [...images].sort((a, b) => a.id.localeCompare(b.id)), [images]);

  const visibleImages = useMemo(() => hideSelectedImages
    ? sortedImages.filter(image => !hiddenImages[image.id])
    : sortedImages, [hideSelectedImages, sortedImages, hiddenImages]);

  const updateHiddenImages = useCallback((id, isSelected) => {
    if (isSelected && hideSelectedImages) {
      setTimeout(() => setHiddenImages(prev => ({ ...prev, [id]: true })), 3000);
    } else {
      setHiddenImages(prev => {
        const newHidden = { ...prev };
        delete newHidden[id];
        return newHidden;
      });
    }
  }, [hideSelectedImages]);

  const toggleImageSelection = useCallback((id) => {
    setSelectedImages(prev => {
      const newSelection = { ...prev, [id]: !prev[id] };
      updateHiddenImages(id, newSelection[id]);
      onSelectionChange(Object.keys(newSelection).filter(id => newSelection[id]));
      return newSelection;
    });
  }, [updateHiddenImages, onSelectionChange]);

  const handleSelectAll = useCallback(() => {
    const newSelection = initializeSelection(visibleImages.map(image => image.id));
    setSelectedImages(newSelection);

    if (hideSelectedImages) {
      setTimeout(() => {
        const newHidden = initializeSelection(visibleImages.map(image => image.id));
        setHiddenImages(prev => ({ ...prev, ...newHidden }));
      }, 3000);
    }

    onSelectionChange(visibleImages.map(image => image.id));
  }, [visibleImages, hideSelectedImages, onSelectionChange]);

  const handleSelectRandom = useCallback(() => {
    // Create array of all visible image IDs
    const allImageIds = visibleImages.map(image => image.id);

    // Calculate how many images to select (75% rounded down)
    const selectCount = Math.floor(allImageIds.length * 0.75);

    // Randomly shuffle the array
    const shuffled = [...allImageIds].sort(() => Math.random() - 0.5);

    // Take first 75% of shuffled array
    const selectedIds = shuffled.slice(0, selectCount);

    // Create new selection object
    const newSelection = initializeSelection(selectedIds);
    setSelectedImages(newSelection);

    // Handle hiding selected images if enabled
    if (hideSelectedImages) {
      setTimeout(() => {
        const newHidden = initializeSelection(selectedIds);
        setHiddenImages(prev => ({ ...prev, ...newHidden }));
      }, 3000);
    }

    // Notify parent of selection change
    onSelectionChange(selectedIds);
  }, [visibleImages, hideSelectedImages, onSelectionChange]);

  const handleSelectNone = useCallback(() => {
    setSelectedImages({});
    setHiddenImages({});
    onSelectionChange([]);
  }, [onSelectionChange]);

  const calculateColumnCount = useCallback((imageCount) => Math.min(5, Math.max(2, Math.ceil(Math.sqrt(imageCount / 2)))), []);

  const columnCount = useMemo(() => calculateColumnCount(visibleImages.length), [visibleImages.length, calculateColumnCount]);

  return (
    <div className="flex flex-col h-full">
      {visibleImages.length > 0 && (
        <div className="flex justify-start px-4 py-2 gap-4">
          <button onClick={handleSelectAll} className="text-sm text-primary border-primary border px-2 rounded-lg">
            All
          </button>
          <button onClick={handleSelectRandom} className="text-sm text-primary border-primary border px-2 rounded-lg">
            Random
          </button>
          {!hideSelectedImages && (
            <button onClick={handleSelectNone} className="text-sm text-primary border-primary border px-2 rounded-lg">
              None
            </button>
          )}
        </div>
      )}

      <div className={`p-${gapSize} relative overflow-y-auto h-full`}>
        {visibleImages.length === 0 && (
          <div className="w-full h-[calc(100dvh-250px)] flex items-center justify-center text-gray-500">
            Waiting for others to join...
          </div>
        )}
        <MasonryLayout columnCount={columnCount} gapSize={gapSize}>
          {visibleImages.map((image) => (
            <ImageItem
              key={image.id}
              image={image}
              isSelected={selectedImages[image.id]}
              columnCount={columnCount}
              onToggle={toggleImageSelection}
            />
          ))}
        </MasonryLayout>
      </div>
    </div>
  );
});

export default MasonryGallery;
