import React, { useState, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '230px'
};

const directionBtn = {
  color: "var(--primary-color)"
}

function MapComponent({ apiKey, zoom = 10, name, address }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey
  });

  const [map, setMap] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(null);

  useEffect(() => {
    if (isLoaded && address) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const location = results[0].geometry.location;
          setCoordinates({
            lat: location.lat(),
            lng: location.lng()
          });
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    }
  }, [isLoaded, address]);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleMarkerClick = () => {
    setInfoWindowOpen(true);
  };

  const handleInfoWindowClose = () => {
    setInfoWindowOpen(false);
  };

  const mapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false
  };

  if (!coordinates) return null;

  const destination = `${name} ${address}`;
  const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(destination)}`;

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={coordinates}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={mapOptions}
    >
      <Marker
        position={coordinates}
        onClick={handleMarkerClick}
      >
        {infoWindowOpen && (
          <InfoWindow
            position={coordinates}
            onCloseClick={handleInfoWindowClose}
          >
            <div className='app-info-window'>
              <h3>{name}</h3>
              <p>{address}</p>
              <a href={googleMapsUrl} className='direction-btn' style={directionBtn} target="_blank" rel="noopener noreferrer">
                Get Directions
              </a>
            </div>
          </InfoWindow>
        )}
      </Marker>
    </GoogleMap>
  ) : null;
}

export default React.memo(MapComponent);