import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import UserAPI from "../../api/UserAPI";
import EventsAPI from "../../api/EventsAPI";
import { PrimaryButton, SecondaryButton, LinkButton } from "../Buttons/buttons";
import Popup from "./Popup";
export const TEXT_NOTIFICATION_FIELD = "allowTextNotifications";

const Notifications = ({ isOpen, onClose, onChange }) => {


  if (!isOpen) return null;

  return (
    <Popup onCancel={onClose}>
      <TextNotifications />
    </Popup>
  );
};
export const TextNotifications = ({ event }) => {
  // --- State Management ---
  const [signedUpForTexts, setSignedUpForTexts] = useState(false);
  const { globalState } = useContext(GlobalContext);

  // --- Effects ---
  useEffect(() => {
    if (globalState.user) {
      getStatusOfNotifications(globalState.user);
    }
  }, [globalState.user]);

  // --- API Functions ---
  const getStatusOfNotifications = async (user) => {
    const userAccount = await UserAPI.getUserByID(user?.userID);
    if (userAccount && userAccount.length) {
      const userAccountAuth = userAccount[0][TEXT_NOTIFICATION_FIELD];
      let finalAuth = userAccountAuth;
      if (event) {
        const eventTextAuth = event?.eventUsers?.[globalState.user.userID]?.[TEXT_NOTIFICATION_FIELD];
        finalAuth = eventTextAuth && userAccountAuth;
      }

      setSignedUpForTexts(finalAuth);
    }
  };

  const handleTextNotificationsChange = async () => {
    const newValue = !signedUpForTexts;
    await signUpForTextNotifications(newValue);
  };

  const signUpForTextNotifications = async (value) => {
    UserAPI.updateUser(
      globalState.user.userID,
      TEXT_NOTIFICATION_FIELD,
      value
    );
    if (event) {
      EventsAPI.updateEvent(event.id, {
        [`eventUsers.${globalState.user.userID}.${TEXT_NOTIFICATION_FIELD}`]: value
      })
    }
    setSignedUpForTexts(value);
  };

  // --- UI Component ---
  return (
    <>
      <div className="h-full flex flex-col justify-around ">
        {/* Header Section */}
        <div className="flex justify-center items-center mb-6 ">

          <h2 className="text-xl font-bold text-stone-700">
            Text Notifications
          </h2>
        </div>

        {/* Description */}
        <p className="text-stone-500 mb-6">
          Stay in the loop! Enable SMS notifications to receive important
          updates of the event.
        </p>
      </div>
      <div className="h-full w-full">
        {/* Toggle Switch */}
        <div className="flex items-center justify-between" onClick={handleTextNotificationsChange}>
          <div className="flex flex-col justify-start" >
            <span className="font-medium text-gray-700 text-left">
              Enable notifications
            </span>
            <span className="text-sm text-stone-400">
              {signedUpForTexts
                ? "You will receive SMS updates"
                : "SMS notifications are disabled"}
            </span>
          </div>
          <button

            className={
              `relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-300  outline-none ring-2 ring-offset-2
              ${signedUpForTexts ? "bg-primary  ring-primary" : "bg-stone-200 ring-stone-200"}`
            }
          >
            <span
              className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform duration-300 
                ${signedUpForTexts ? "translate-x-6" : "translate-x-1"}`}
            />
          </button>
        </div>
      </div>

    </>

  );
};
export default Notifications;
