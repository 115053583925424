// src/Profile/ProfileCard.js

import React, { useContext, useRef, useState, useEffect } from "react";
import "./ProfileCard.scss";
import { GlobalContext } from "../context/GlobalContext";
import UserAPI from "../api/UserAPI";
import useImageResizer from "../utils/useImageResizer";
import Api from "../api/api";
import {
  LinkButton,
  PrimaryButton,
  PrimaryButtonSmall,
  SecondaryButtonSmall,
} from "../components/Buttons/buttons";
import Utility from "../utils/utility";
import { PencilIcon, UserMinusIcon, BellAlertIcon } from "@heroicons/react/24/solid";
import EventsAPI from "../api/EventsAPI";
import MenuDropdown from "../components/MenuDropdown";
import DeleteAccountPopup from "../components/Popup/DeleteAccount";
import Notifications from "../components/Popup/Notifications";

const ProfileCard = ({ showCue, onUserUpdate = new Function(), isSitter }) => {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const { user } = globalState;

  const fileInputRef = useRef(null);
  const [showLoading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(user.name);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showNotificationsPopup, setShowNotificationsPopup] = useState(false);
  const onToggleDeleteAccount = async () => {
    setShowDeletePopup(prev => !prev);
  }
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const onShowNotifications = () => {
    setShowNotificationsPopup(prev => !prev);
  };
  const menuItems = [
    {
      label: "Notifications",
      icon: BellAlertIcon,
      action: onShowNotifications,
    },
    {
      label: "Delete Account",
      icon: UserMinusIcon,
      action: onToggleDeleteAccount,
      style:
        "text-rose-500 hover:bg-gray-100 hover:text-gray-900 border-t border-slate-200",
    },
  ];
  const handleNameSubmit = async () => {
    toggleEditMode();
    const updatedUserObj = await UserAPI.updateUser(user.userID, "name", name);
    onUserUpdate({ name })
    console.log("updatedUserObj", updatedUserObj);
    setGlobalState((prev) => ({
      ...prev,
      user: updatedUserObj,
    }));
    localStorage.setItem("user", JSON.stringify(updatedUserObj));
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const onEditPhoto = () => {
    fileInputRef.current.click();
  };

  const { setImageForResize } = useImageResizer(600, 800);

  const selectImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageForResize(file);
      setLoading(true);
      const resizedFile = await setImageForResize(file);
      const photoURL = await UserAPI.uploadUserProfilePhoto(
        user.userID,
        resizedFile
      );
      onUserUpdate({ photoURL })
      const updatedUserObj = await UserAPI.updateUser(
        user.userID,
        "photoURL",
        photoURL
      );

      setGlobalState((prev) => ({
        ...prev,
        user: updatedUserObj,
      }));
      localStorage.setItem("user", JSON.stringify(updatedUserObj));
      setLoading(false);
    }
  };

  const onLogout = async () => {
    await Api.signOut();
    window.location.reload()
    // localStorage.removeItem('user')
    // setGlobalState(prev => {
    //   return {
    //     ...prev,
    //     user: null
    //   }
    // })
    // window.location.reload()
  };

  return (
    <div className="profile-container h-full">
      <div>
        {
          showCue
            ?
            (
              <div>
                <div className="text-stone-600 text-lg font-bold">Update your profile</div>
                <div className="text-stone-400 text-sm">Add cues so your partner find you</div>
              </div>
            )
            : (
              <div className="fixed top-5 right-4 z-10">
                <MenuDropdown menuItems={menuItems} ellipsisClassName="text-stone-500" />

              </div>
            )
        }

      </div>
      <div className="profile-header">
        <img
          src={user.photoURL}
          alt="Profile"
          className={`profile-image ${showLoading ? "fade" : null}`}
        />
        <button className="edit-button" onClick={onEditPhoto}>
          {showLoading ? <div className="loader"></div> : "Edit"}
        </button>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={(e) => {
            selectImage(e);
          }}
        />
      </div>
      <div className="flex m-auto space-x-2">
        {isEditing ? (
          <input
            className="profile-name-input"
            value={name}
            onChange={handleNameChange}
            autoFocus
          />
        ) : (
          <h1 className="profile-card-name">{user.name}</h1>
        )}
        {isEditing ? (
          <LinkButton className="edit-profile" onClick={handleNameSubmit}>
            Save
          </LinkButton>
        ) : (
          <LinkButton className="edit-profile" onClick={toggleEditMode}>
            <PencilIcon className="w-4 h-4" />
          </LinkButton>
        )}
      </div>
      {showCue ? (
        <CueCard onUserUpdate={onUserUpdate} isSitter={isSitter} />
      ) : (
        <>
          <p className="profile-phone">{user.phone}</p>
          <div className="mb-2">
            <SecondaryButtonSmall onClick={(e) => onLogout()}>
              {" "}
              Logout{" "}
            </SecondaryButtonSmall>
          </div>
        </>
      )}

      {
        <DeleteAccountPopup isOpen={showDeletePopup} onClose={onToggleDeleteAccount} />

      }{
        <Notifications
          isOpen={showNotificationsPopup}
          onClose={onShowNotifications}

        />
      }
    </div>
  );
};

export default ProfileCard;

function CueCard({ onUserUpdate, isSitter }) {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [text, setText] = useState(globalState?.user?.bio);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const textareaRef = useRef(null);

  useEffect(() => {
    if (isEditing && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isEditing]);

  const handleChange = (event) => {
    if (event.target.value.length <= 60) {
      setText(event.target.value);
    }
  };

  const updateCues = async () => {
    setIsEditing(!isEditing);

    if (!isEditing) return;

    setIsLoading(true);
    await UserAPI.updateUser(globalState.user?.userID, "bio", text);
    onUserUpdate({ bio: text })

    const updateCurrUser = {
      ...globalState.user,
      bio: text,
    };
    localStorage.setItem("user", JSON.stringify(updateCurrUser));
    setGlobalState((prev) => ({
      ...prev,
      user: updateCurrUser,
    }));
    setIsLoading(false);
  };

  let placeholderText = "e.g.: I'm in a blue shirt and black jeans.";
  if (isSitter) {
    placeholderText = `e.g.: I am in a beige top & white jeans by the bar. `;
  }


  return (
    <div className="cues ">
      {isEditing ? (
        <textarea
          ref={textareaRef}
          value={text}
          onChange={handleChange}
          placeholder={placeholderText}
          rows="2"
          className="cue-input"
        />
      ) : (
        <div
          className="mb-2 border rounded-md text-slate-600 bg-slate-50 p-2 text-sm"
          onClick={(e) => setIsEditing(true)}
        >
          {globalState.user?.bio?.length
            ? globalState.user?.bio
            : placeholderText}
        </div>
      )}

      <SecondaryButtonSmall className=" h-10" onClick={(e) => updateCues()}>
        {isLoading ? (
          <div className="loader"></div>
        ) : isEditing ? (
          "Save Cues"
        ) : (
          <p className="flex items-center gap-2"><PencilIcon className="w-4 h-4" /> Edit Cues</p>
        )}
      </SecondaryButtonSmall>
    </div>
  );
}