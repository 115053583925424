import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import UserAPI from "../../api/UserAPI";
import api from "../../api/api";
import { PrimaryButton, SecondaryButton, LinkButton } from "../Buttons/buttons";
import Popup from "./Popup";
export const TEXT_NOTIFICATION_FIELD = "allowTextNotifications";

const DeleteAccountPopup = ({ isOpen, onClose, onChange }) => {


  if (!isOpen) return null;

  return (
    <Popup onCancel={onClose}>
      <DeleteAccount />
    </Popup>
  );
};
function DeleteAccount() {
  const { globalState, setGlobalState } = useContext(GlobalContext);
  const [confirmText, setConfirmText] = React.useState('');

  const handleDelete = () => {
    if (confirmText === 'DELETE') {
      alert('Account deleted!');

      setConfirmText('');
    }
  };

  const onDeleteConfirm = async () => {
    if (confirmText === 'DELETE') {
      await api.deleteUserAccount(globalState.user);
      setGlobalState({ user: null });
      window.location.href = '/';
      window.location.reload();

    }
  }
  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center justify-center space-x-3 w-full ">
          <div className="bg-red-100 p-2 rounded-full">
            <svg className="w-6 h-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <h2 className="text-xl font-bold text-gray-900">Delete Account</h2>
        </div>

        <p className="text-gray-600">
          This action cannot be undone. This will permanently delete your account and remove all your data from our servers.
        </p>

        <div className="bg-gray-50 p-4 rounded-lg space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Type DELETE to confirm
          </label>
          <input
            type="text"
            value={confirmText}
            onChange={(e) => setConfirmText(e.target.value)}
            className="w-full px-3 py-2 border border-gray-100 rounded-md 
                 focus:outline-none focus:ring-2 focus:ring-red-500 
                 focus:border-red-500"
            placeholder="Type DELETE"
          />
        </div>


      </div>
      <div className="flex space-x-3 w-full">
        <button
          onClick={() => {

            setConfirmText('');
          }}
          className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg"
        >
          Cancel
        </button>
        <button
          onClick={onDeleteConfirm}
          // disabled={confirmText !== 'DELETE'}
          // className={`flex-1 px-4 py-2 rounded-lg transition-colors duration-200 
          //        focus:outline-none focus:ring-2 focus:ring-red-500 
          //        focus:ring-offset-2 
          //        ${confirmText === 'DELETE'
          //     ? 'bg-red-500 text-white hover:bg-red-600'
          //     : 'bg-red-300 text-white cursor-not-allowed'}`}
          className="flex-1 px-4 py-2 bg-red text-white rounded-lg 
                "
        >
          Delete Account
        </button>
      </div>
    </>
  );
}
export default DeleteAccountPopup;
