import React, { useState, useEffect, useContext } from "react";
import Api from "../../api/api";
import {
  PhoneIcon,
  XMarkIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/solid";
import "./Sheets.scss";
import UserAPI from "../../api/UserAPI";
import { GlobalContext } from "../../context/GlobalContext";
import { motion } from "framer-motion";

// Main App Component
const Sheets = ({ onComplete, onCancel, children, closeBtn = <XMarkIcon />}) => {

  const { setGlobalState } = useContext(GlobalContext);
  const beforeCancel = () => {
    onCancel();
  };

  return (
    <div className="sheets">
      <div className="cancelArea" onClick={onCancel}></div>

      <motion.div
        className="container"
        initial={{ y: "100vh" }}
        animate={{ y: 0 }}
        exit={{ y: "100vh" }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        // drag="y"
        // dragConstraints={{ top: 0, bottom: 0 }}
        // dragElastic={0.2}
        // onDragEnd={(event, info) => {

        //   if (info.point.y > 300) {
        //     onCancel();
        //   }
        // }}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="closeBtn" onClick={onCancel}>
          {closeBtn}
        </div>
        <div className="content">{children}</div>
      </motion.div>
    </div>
  );
};

export default Sheets;
