// Api.js
import {
  signInWithPhoneNumber,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  RecaptchaVerifier,
  updateProfile,
  deleteUser
} from 'firebase/auth';
import {
  collection,
  addDoc,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  getDocs
} from 'firebase/firestore';
import { auth, db } from '../firebase';



class Api {
  // Authentication methods
  static async signInWithPhone(phoneNumber, appVerifier) {
    try {
      const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      return confirmationResult;
    } catch (error) {
      console.log(error);

      return error
    }
  }

  static async verifyCode(confirmationResult, verificationCode) {
    try {
      const userCredential = await confirmationResult.confirm(verificationCode);
      return userCredential.user;
    } catch (error) {
      throw error;
    }
  }
  static async updateFBUserProfile(user, data) {
    try {
      const didUpdate = await updateProfile(user, data)
      return didUpdate
    } catch (error) {
      throw error
    }

  }
  static async signOut() {
    try {
      await firebaseSignOut(auth);
    } catch (error) {
      throw error;
    }
  }

  static onAuthStateChanged(callback) {
    return onAuthStateChanged(auth, callback);
  }

  // Firestore methods
  static async addDocument(collectionName, document) {
    try {
      const docRef = await addDoc(collection(db, collectionName), document);
      return docRef.id;
    } catch (error) {
      throw error;
    }
  }


  static async getDocument(collectionName, docId, nestedCollectionName = null) {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error('Document not found');
      }

      const docData = docSnap.data();

      if (!nestedCollectionName) {
        return docData;
      }

      const nestedCollectionRef = collection(docRef, nestedCollectionName);
      const nestedCollectionSnap = await getDocs(nestedCollectionRef);

      const nestedCollectionData = nestedCollectionSnap.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      return {
        ...docData,
        [nestedCollectionName]: nestedCollectionData
      };
    } catch (error) {
      throw error;
    }
  }

  static async updateDocument(collectionName, docId, data) {
    try {
      const docRef = doc(db, collectionName, docId);
      await updateDoc(docRef, data);
    } catch (error) {
      throw error;
    }
  }

  static async deleteDocument(collectionName, docId) {
    try {
      const docRef = doc(db, collectionName, docId);
      await deleteDoc(docRef);
    } catch (error) {
      throw error;
    }
  }

  static async getCollection(collectionName) {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const docs = [];
      querySnapshot.forEach(doc => docs.push({ id: doc.id, ...doc.data() }));
      return docs;
    } catch (error) {
      throw error;
    }
  }

  // ReCAPTCHA verifier
  static setupRecaptcha(containerId, onSignInSubmit) {
    try {
      const appVerifier = new RecaptchaVerifier(auth, containerId, {
        'size': 'invisible',
        'callback': (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit();
        },
        'expired-callback': () => {
          console.error('reCAPTCHA expired, please solve it again');
        },
      });
      return appVerifier;
    } catch (error) {
      console.log(error);
    }
  }
  static async deleteUserAccount(user) {


    try {
      // 🎯 Step 2: Delete user data from Firestore
      const userDocRef = doc(db, 'users', user.userID);
      await deleteDoc(userDocRef);

      // 🎯 Step 3: Delete the user authentication
      await deleteUser(auth.currentUser);

      // ✅ Success message
      console.log('User successfully deleted!');

    } catch (error) {
      // ❌ Error handling
      console.error('Error deleting user:', error.message);
      throw error;
    }
  }
}

export default Api;