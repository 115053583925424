import { useState, useCallback, useEffect, useRef } from 'react';
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

export default function MenuDropdown({ menuItems, btnStyle, bgStyle, ellipsisClassName = "text-stone-300" }) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  // Toggle dropdown open/close state
  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  const handleClickOutside = useCallback(
    (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu()
      }
    },
    [menuRef]
  );

  // Close dropdown on Escape key press
  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Escape') {
      closeMenu()
    }
  }, []);

  const closeMenu = (thenExecute = new Function()) => {
    setIsOpen(false);
    thenExecute()
  }

  // Set up event listeners for click outside and Escape key
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClickOutside, handleKeyDown]);

  return (
    <div ref={menuRef} className="relative flex justify-end  text-left  [width:50px] [height:50px] ">
      <button
        onClick={toggleMenu}
        className={`flex items-center rounded-full focus:outline-none ${btnStyle || 'text-white hover:text-gray-300'}`}
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <span className="sr-only">Open options</span>
        <EllipsisVerticalIcon aria-hidden="true" className={`h-6 w-6 filter drop-shadow-lg ${ellipsisClassName}`} />
      </button>

      {
        isOpen && (
          <div
            className={`absolute top-11 right-0 z-10 mt-2 max-w-56 origin-top-right rounded-md shadow-lg ring-1 ring-opacity-5 focus:outline-none ${bgStyle || 'bg-white ring-black'}`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div className="py-1" role="none">
              {menuItems.map((item, index) => (
                <button
                  key={index}
                  onClick={e => closeMenu(item.action)}
                  className={`flex w-full px-4 py-2 text-sm items-center justify-between truncate ${item.style || 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'} `}
                  role="menuitem"
                  tabIndex={-1}
                >

                  <div className="flex items-center">
                    <item.icon className="h-4 w-4 mr-2" />
                  </div>
                  <div className="flex items-center">
                    {item.label}
                  </div>
                </button>
              ))}
            </div>
          </div>
        )
      }
    </div >
  );
}